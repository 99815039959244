@use '@angular/material' as mat;
@import "src/mixin.scss";

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


// THIS --> https://material.io/design/color/#tools-for-picking-colors
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=fa8242

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import url("https://use.typekit.net/jnq3uxd.css");
@import "./variables";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat.define-typography-config(
    $font-family: 'Poppins Regular, sans-serif'
);


// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-component-typographies($custom-typography);
@include mat.core();

$custom-jarvis: (
    50: #bfbfd2,
    100: #2e2c84,
    200: #1a1973,
    300: #08075c,
    400: #050448,
    500: #040338,
    600: #03022f,
    700: #02022a,
    800: #040428,
    900: #03031f,
    
    A100: #ffd180,
    A200: #ffab40,
    A400: #ff9100,
    A700: #ff6d00,
    contrast: (
        50: rgba(black, 0.87),
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: rgba(black, 0.87),
        A200: rgba(black, 0.87),
        A400: rgba(black, 0.87),
        A700: white,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$firestarter-primary: mat.define-palette($custom-jarvis);
$firestarter-accent: mat.define-palette($custom-jarvis, 400, 500, 600);

// The warn palette is optional (defaults to red).
$firestarter-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$firestarter-theme: mat.define-light-theme($firestarter-primary, $firestarter-accent, $firestarter-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($firestarter-theme);

/* You can add global styles to this file, and also import other style files */

.mat-mdc-table {
  .mat-mdc-cell {
    padding-right: 5px;
  }
  .mat-mdc-cell:last-of-type {
    padding-right: 24px;
  }
}  

.mat-drawer {
  background: transparent;
}

.mat-drawer-side {
  border-right: none;
}

// From https://github.com/tailwindlabs/tailwindcss/discussions/9993
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

input.mat-mdc-input-element {
  line-height: 1em;
}

html, body { height: 100%; }
body { 
  margin: 0; font-family: 'Poppins', sans-serif;

  h1, h2 {
    font-family: 'Poppins Semibold', sans-serif;
  }

  a {
    font-family: 'Poppins Regular', sans-serif;
    letter-spacing: 1px;
  }

  p {
    font-family: 'Poppins Regular', sans-serif;
  }

  button, input {
    font-family: 'Poppins Medium', sans-serif;
  }
}

a {
  color: $primary;
  text-decoration: none;
}

div {
  box-sizing: border-box;
}

.pageRoot { // more of a placeholder class
  position: relative;
}

.blockingDiv, .darkDiv, .clearDiv {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.darkDiv {
  background-color: rgba(#000, 0.4);
}
.clearDiv { // clearDiv lets clicks "pass through"
  pointer-events: none; // kids need "pointer-events: all" to override this
}

.pageContent {
  margin-left: 20px;
}

.pageHeader { // class supposed to be added to h1
  color: $primary;
  font-size: 48px;
}

.mainTitle {
  color: $primary;
  font-size: 40px;
  font-weight: bold;
  margin: 10px 0px;
}

.sectionTitle {
  margin: 20px 0 5px 0;
  font-size: 20pt;
  font-weight: bold;
}

.sectionSubtitle {
  margin: 15px 0 5px 0;
  font-size: 16pt;
  font-weight: bold;
}

.sectionTable {
  margin-bottom: 25px;
  min-width: calc(100% - 40px);
  border-radius: 10px;
  border: 1px solid lightgrey;
  border-spacing: 0;
  padding: 9px 25px 17px;
  
  tr.mat-mdc-header-row {
    height: 40px;
  }
  tr.mat-mdc-row {
    height: 40px;
  }
  th {
    text-align: start;
    border-bottom: 1px solid #ccc;
  }
  td {
    padding: 14px 15px 0 0;
    font-size: 12px;
  }
}

.tabDiv {
  display: flex;
  background-color: #eee;
  border-radius: 30px;
  width: calc(100% - 40px);
  margin-bottom: 5px;

  .tabButton {
    border-radius: 30px;
    text-align: center;
    width: fit-content;
    padding: 10px 15px;
    margin: 0 2px;
  }
}

.headerBar {
  float: right;
  position: relative;
  top: -83px;
  margin-right: 15px;

  .zones {
    display: flex;
  }
}

.searchBox {
  margin: 0 10px;
  background-color: #ccc;
  border-radius: 30px;
  height: 47px;
  width: 156px;

  input {
    position: relative;
    top: 12px;
    background-color: transparent;
    border-color: transparent;
    color: #fff;
    font-size: 17px;
    padding: 0 14px;
    width: calc(100% - 28px - 33px);
    
    &:focus {
      outline: none!important;
    }
  }

  .mat-icon {
    position: absolute;
    right: 28px;
    top: 8px;
    color: #fff;
    font-size: 34px;
  }
}

.mat-mdc-menu-content {
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.textLink {
  color: $textlink;
  cursor: pointer;
}

.modestButton {
  box-shadow: 1px 1px 3px 0px #4a4a4a;
  background: #fff;
  color: #424242;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  line-height: 36px;
  padding: 0 16px;
  cursor: pointer;
  &:hover {
    background: #ddd;
  }
  &:active {
    background-color: #bbb;
  }
}

.scaryButton {
  color:#be1212;
  background-color: #e0e0e0;
  &:hover {
    color: #fff;
    background-color: #f44336;
  }  
}

.menuItem {
  display: inline-block;
  cursor: pointer;

  a {
    display: block;
    text-align: center;
    text-decoration: none;      
  }

  a:hover {
    background-color: #EFF6FF;
    text-align: center;
    align-items: center;
    color: #1D4ED8;
  }

  .active-tab {
    background-color: #2563EB;
    color: white;
  }  
}

.jarvis-table {
  width: 1000px;
  border-radius: 30px;
  padding: 25px;
  text-align: start;
  box-shadow: 3px 3px 13px lightgrey;

  @include respond(phone){
    width: 100vw;
  }

  th {
    border-bottom: 1px solid #ddd;
  }

  .pivot-th {
    text-align: center;
    padding-left: 10px;
  }

  tr {
    height: 40px;
  }

  td {
    padding-right: 10px;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word;
  }

  .pivot-td {
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word;
    border-right: 1px solid #ddd;
  }

  .table-header-name {
    font-weight: bold;
    text-align: start;
  }
}

.jarvis-paginator {
  width: fit-content;
  margin-top: 5px;
  margin-left: auto;
  border-radius: 30px;
  border: 1px solid lightgrey;
  text-align: start;
}

.form-frame {
  z-index: 100;
  @include vertical-center;
  @include horizontal-center;
  @include dialog-frame;
  width: fit-content;
}

.form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-title {
  font-family: 'Poppins Semibold', sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  margin: 14px 0 22px;
  text-align: center;
  @include respond(tablet) {
    font-size: 18px;
  }
}

// Override checkbox color
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $primary !important;
}
// Workaround for missing style if focus is set by program
// (from https://stackoverflow.com/questions/72926137/programatically-set-focus-for-material-checkbox)
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-persistent-ripple {
  .mat-mdc-checkbox.cdk-focused & { opacity: 0.12; }
}

.admin-options {
  position: relative;
  right: 23px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 10px;
}

.adminZone {
  margin: 20px;

  .unhideBtn {
    margin: 5px;
  }
}

.warningMessage {
  margin: 50px auto;
  padding: 50px;
  width: 80%;
  height: 300px;
  border-radius: 10px;
  background-color: #e4f1fa;

  .text {
    @include vertical-center;
    text-align: center;
    font-size: 110%;
    color: #2d363f;
  }
}

.filters-stack { // Do not use this anymore: create reusable filters in filter-selector, thanks!
  margin: auto;
  padding: 15px 15px 0px 15px;
  height: fit-content;
  align-content: center;
  justify-items: center;
  justify-content: center;
  font-size: 14px;
}

.sidenav-container {
  margin-top: 10px;
}

// show more button
.show-more {
  margin: auto;
  margin-top: 30px;
  width: 200px;

  @include respond(phone) {
    text-align: center;
  }
}

.component-render-area {
  min-height: calc(100vh - 74px);
  height: 100%;
}

.horizontal-stack {
    width: 88%;
    display: flex;
    margin: 10px auto;
    flex-wrap: wrap;

  @include respond(phone) {
    flex-flow: column wrap;
    align-content: center;
  }
}

.section-title{
  font-size: 1.15em;
  font-weight: bolder; 
  margin: 10px 0px 10px 10px;
}

.section-toggle {
  margin: 10px 0px 10px 0;
  @include respond(phone) {
    margin: auto;
    flex-direction: column;
  }
}
// Styling for .md code blocks
.markdown {
  ol > li > pre,
  ul > li > pre,
  pre {
  background-color:#edf5ff;
  border-radius: 6px;  
  padding: 16px;
  line-height: 2em;
  overflow: auto;
  margin: 0.5rem 0 1rem 0;
  }

  ol, ul {
    list-style: revert;
    margin: revert;
    padding: revert;
  }

  h2, h3 {
    padding: 1.5rem 0 0.6rem 0;
    font-weight: 800;
  }
}
.section-container{
  display: flex;

    &.-col{
      flex-direction: column;
      width: 100%;
    }
    &.--card{
      flex-wrap: wrap;
    }
}
//  matToolTip
.cdk-overlay-pane {
  &.mat-mdc-tooltip-panel {
    .mat-mdc-tooltip { 
    border:  1px solid #eeeeee;
    border-radius:  3px;
    font-size:  14px;
    padding:  10px; 
    } 
  } 
}

.snackBarPreText {
  text-align: start;
  white-space: pre-line;
}

.errorPageBox {
  @include vertical-center;
  @include horizontal-center;
  width: fit-content;
  max-width: 650px;
  top: 35%;
  margin: 50px auto;
  text-align: justify;
  font-size: larger;

  .title {
    font-size: xx-large;
  }
  p {
    margin: 10px 0;
  }
}

