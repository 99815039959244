// COLORS
$default: #AAAAAC;
$primary: #080735;
$secondary: #fbda3b;
$textlink: #4057ff;
$success: #5cb85c;
$info: #3bb4d8;
$warning: #f0ad4e;
$danger: #d9534f;

$tailwind-primary: #2563EB;
$tailwind-secondary: #EFF6FF;


$button-solid-text: $primary; // default font color for solid buttons
$button-modest-solid-text: #000; // overrides the above

$button-important-face: $secondary;
$button-modest-face: #fff;
$button-modest-outlined: #333;


// CONTAINERS
$border-radius: 4px;
$card-background: #FFF;

// STATS CARDS
$stats-blue: #3b75af;