@import "src/variables";

@mixin vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontal-center {
  margin: auto;
}

@mixin dialog-frame {
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 60%) 1px 1px 4px -1px;
  padding: 10px 20px 7px; // unsure about this 3 px at bottom weird issue
  text-align: center;
}

@mixin info-frame {
  position: relative;
  padding: 1px 18px;
  margin: 10px;
  text-align: left;

  .title {
    color: $primary;
    font-size: 40px;
    font-weight: bold;
    margin: 10px 0px;
    padding-right: 30px; // room for edit button
  }
  .editButton {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .subtitle {
    font-size: 120%;
    font-weight: bold;
    margin: -10px 0 15px;
  }
  .info {
    margin: 10px 0;
    .infoValue {
      font-weight: bold;
    }
  }
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (min-width: 20em) and (max-width: 30em) { @content };  //320-480
  }
  // @if $breakpoint == phone {
  //   @media (max-width: 37.5em) { @content };  //600
  // }
  @if $breakpoint == tablet {
    @media (max-width: 56.25em) { @content };  //900
  }
  @if $breakpoint == laptopSmall {
    @media (max-width: 80em ) { @content }; //1280
  } 
}